import {useMutation} from "@tanstack/react-query";
import API from "../services/api";

export default function useCheckIn() {
    const mutation = useMutation({
        mutationFn: (checkinValues) => {
            const queryParam = {
                organisation: checkinValues.organisation,
                orgTiers: checkinValues.orgTiers,
            }
            delete checkinValues.organisation;
            delete checkinValues.orgTiers;
            console.log('adasdasd', queryParam, checkinValues)
            return API.createCheckIn(checkinValues, queryParam)
        },
        onSuccess: (data) => {
            // Handle success
            console.log("Check-in created successfully:", data?.data);
        },
        onError: (error) => {
            // Handle error
            console.error("Error creating check-in:", error);
        },
    });

    const { data, mutate: createCheckIn, isSuccess, error} = mutation;

    return {
        data,
        createCheckIn,
        isSuccess,
        error
    };
}